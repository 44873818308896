<template>
    <div class="pageV">
        <img class="imageIv" src="../../assets/img/jianshe.svg" />
        <span class="textTv0">{{ $t('Reminder') }}</span>
        <span class="textTv1">{{ $t('Please_visit_the_wallet') }}</span>
        <span class="textTv2">{{ $t('Decentralized_website') }}</span>
        <span class="textTv3" @click.prevent="onCopyClick" :data-clipboard-text="hredf + '://' + hosts">
            {{ $t('Copy_URL') }}
        </span>
        <span>
            {{ aaa }}
        </span>
        <br />
        <span>
            {{ name }}
        </span>
    </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
    data() {
        return {
            hredf: window.location.protocol,
            hosts: window.location.host,
            aaa: window.navigator.userAgent,
            name: navigator.platform
        };
    },
    created(){
        this.on();
    },
    methods: {
        on() {
            this.name= "Browser CodeName: " + navigator.appCodeName + "\n\r\n\r\n\r-----------------" +
                "Browser Name: " + navigator.appName + "\n\r\n\r\n\r---------------" +
                "Browser Version: " + navigator.appVersion + "\n\r\n\r\n\r----------" +
                "Cookies Enabled: " + navigator.cookieEnabled + "\n\r\n\r\n\r----------" +
                "Browser Language: " + navigator.language + "\n\r\n\r\n\r----------" +
                "Browser Online: " + navigator.onLine + "\n\r\n\r\n\r----------" +
                "Platform: " + navigator.platform + "\n\r\n\r\n\r----------" +
                "User-agent header: " + navigator.userAgent + "\n\r\n\r\n\r----------"+
                window.ethereum+"\n\r\n\r-------"+window.web3+ "\n\r\n\r\n\r----------"+
                window.web3?.currentProvider+"\n\r\n\r----"+window.web3?.currentProvider?.isCoinbaseWallet;
        },
        onCopyClick() {
            const clipboard = new Clipboard('.textTv3')
            clipboard.on('success', e => {
                this.$toast.success(this.$t('Copied_successfully'))
            })
        }
    }
}
</script>

<style lang="less" scoped>
.pageV {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.imageIv {
    margin-top: 200px;
}

.textTv0 {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
}

.textTv1 {
    color: #666;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
}

.textTv2 {
    color: #999;
    font-size: 17px;
    font-weight: 500;
    margin-top: 10px;
}

.textTv3 {
    height: 48px;
    margin-top: 20px;
    border-radius: 45px;
    background-color: #f0b82d;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    padding-left: 30px;
    padding-right: 30px;
}
</style>